import { useStaticQuery, graphql } from "gatsby"
import React from "react"
import { Box, Flex } from "@chakra-ui/core"
import AwesomeSlider from "react-awesome-slider"
import "react-awesome-slider/dist/styles.css"

const Slider = () => {
  const { allFile } = useStaticQuery(
    graphql`
      query {
        allFile(
          sort: { fields: name, order: DESC }
          filter: { relativeDirectory: { eq: "slides" } }
        ) {
          edges {
            node {
              id
              name
              childImageSharp {
                fluid(maxWidth: 1600) {
                  ...GatsbyImageSharpFluid_withWebp_tracedSVG
                }
              }
            }
          }
        }
      }
    `
  )

  React.useEffect(() => {
    setTimeout(() => {
      var slides = document.querySelectorAll("#slides .slide")
      var currentSlide = 0
      var slideInterval = setInterval(nextSlide, 3000)

      function nextSlide() {
        slides[currentSlide].className = "slide"
        currentSlide = (currentSlide + 1) % slides.length
        slides[currentSlide].className = "slide showing"
      }
    }, 1000)
  }, [])

  const slideImages = allFile.edges.map(edge => edge.node)
  console.log({ slideImages })
  return (
    <Box className="slide-container" h="50vh" id="slides">
      {slideImages.map((node, idx) => (
          <Box
            height="100%"
            style={{ backgroundImage: `url(${node.childImageSharp.fluid.src})` }}
            key={idx}
            className="each-slide slide"
          ></Box>
      ))}
    </Box>
  )
}

export default Slider
